// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Theme$Weblab from "../service/Theme.bs.js";
import * as ReactHelmet from "react-helmet";
import * as LoginBase$Weblab from "../components/LoginBase.bs.js";
import * as Core from "@material-ui/core";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as SupabaseClient$Weblab from "../bindings/supabase/SupabaseClient.bs.js";
import * as Styles from "@material-ui/core/styles";
import Weblab_logoSvg from "/src/assets/svg/weblab_logo.svg";

var theme = Styles.createTheme(Theme$Weblab.getThemeProto(false));

function loginReducer(state, action) {
  if (typeof action === "number") {
    return {
            password: "",
            secondPassword: ""
          };
  } else if (action.TAG === /* ChangePassword */0) {
    return {
            password: action._0,
            secondPassword: state.secondPassword
          };
  } else {
    return {
            password: state.password,
            secondPassword: action._0
          };
  }
}

function ResetPassword(Props) {
  var $$location = Props.location;
  var match = React.useReducer(loginReducer, {
        password: "",
        secondPassword: ""
      });
  var resetDispatch = match[1];
  var reset = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setResetAlert = match$1[1];
  var resetAlert = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setAccessToken = match$2[1];
  var accessToken = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var setLoading = match$3[1];
  React.useEffect((function () {
          var params = new URLSearchParams($$location.hash.replace("#", ""));
          var access_tokenOption = params.get("access_token");
          var typeOption = params.get("type");
          if (!(typeOption == null) && !(access_tokenOption == null) && typeOption === "recovery") {
            Curry._1(setAccessToken, (function (param) {
                    return access_tokenOption;
                  }));
          }
          
        }), []);
  return React.createElement(Styles.ThemeProvider, {
              children: null,
              theme: theme
            }, React.createElement(ReactHelmet.Helmet, {
                  children: null
                }, React.createElement("link", {
                      href: "/favicon.png",
                      rel: "icon",
                      type: "image/png"
                    }), React.createElement("title", undefined, "Weblab Password Reset")), React.createElement(Core.Box, {
                  style: {
                    bottom: "0",
                    left: "0",
                    position: "absolute",
                    right: "0",
                    top: "0"
                  },
                  children: null,
                  height: "40%",
                  margin: "auto",
                  width: "40%"
                }, React.createElement(Core.Typography, {
                      align: "center",
                      children: null,
                      color: "primary",
                      variant: "h3",
                      style: {
                        fontWeight: "700",
                        marginBottom: "32px"
                      }
                    }, React.createElement(Core.Box, {
                          clone: true,
                          children: React.createElement(Weblab_logoSvg, {}),
                          height: 64,
                          width: 64
                        }), "Web", React.createElement(Core.Box, {
                          style: {
                            color: theme.palette.secondary.main
                          },
                          children: "lab",
                          display: "inline"
                        })), accessToken !== undefined ? React.createElement(Core.Box, {
                        clone: true,
                        children: React.createElement("form", {
                              onSubmit: (function (evt) {
                                  evt.preventDefault();
                                  if (LoginBase$Weblab.samePassword(reset.password, reset.secondPassword)) {
                                    if (LoginBase$Weblab.strongPassword(reset.password)) {
                                      Curry._1(setLoading, (function (param) {
                                              return true;
                                            }));
                                      SupabaseClient$Weblab.supabase.auth.api.updateUser(accessToken, {
                                                password: reset.password
                                              }).then(function (response) {
                                              var error = response.error;
                                              if (error == null) {
                                                Curry._1(setLoading, (function (param) {
                                                        return false;
                                                      }));
                                                Curry._1(resetDispatch, /* Clear */0);
                                                return Promise.resolve(undefined);
                                              } else {
                                                return Promise.reject(Js_exn.raiseError(error.message));
                                              }
                                            }).catch(function (error) {
                                            Curry._1(setLoading, (function (param) {
                                                    return false;
                                                  }));
                                            Curry._1(setResetAlert, (function (param) {
                                                    return Belt_Option.getWithDefault(Belt_Option.flatMap(Caml_js_exceptions.caml_as_js_exn(Js_exn.anyToExnInternal(error)), (function (prim) {
                                                                      return prim.message;
                                                                    })), "Log in failed.");
                                                  }));
                                            return Promise.resolve(undefined);
                                          });
                                      return ;
                                    } else {
                                      return Curry._1(setResetAlert, (function (param) {
                                                    return "Password must be at least 8 characters long and must contain at least one uppercase letter, one lowercase letter, one digit and one special character.";
                                                  }));
                                    }
                                  } else {
                                    return Curry._1(setResetAlert, (function (param) {
                                                  return "Both passwords must be the same.";
                                                }));
                                  }
                                })
                            }, React.createElement(Core.FormGroup, {
                                  children: React.createElement(Core.FormControlLabel, {
                                        control: React.createElement(Core.TextField, {
                                              margin: "dense",
                                              type: "password",
                                              variant: "outlined"
                                            }),
                                        label: React.createElement(Core.FormLabel, {
                                              children: "New Password"
                                            }),
                                        labelPlacement: "top",
                                        onChange: (function (evt) {
                                            return Curry._1(resetDispatch, {
                                                        TAG: /* ChangePassword */0,
                                                        _0: evt.currentTarget.value
                                                      });
                                          }),
                                        value: reset.password
                                      })
                                }), React.createElement(Core.FormGroup, {
                                  children: React.createElement(Core.FormControlLabel, {
                                        control: React.createElement(Core.TextField, {
                                              margin: "dense",
                                              type: "password",
                                              variant: "outlined"
                                            }),
                                        label: React.createElement(Core.FormLabel, {
                                              children: "Repeat Password"
                                            }),
                                        labelPlacement: "top",
                                        onChange: (function (evt) {
                                            return Curry._1(resetDispatch, {
                                                        TAG: /* ChangeSecondPassword */1,
                                                        _0: evt.currentTarget.value
                                                      });
                                          }),
                                        value: reset.secondPassword
                                      })
                                }), resetAlert !== undefined ? React.createElement(Core.Typography, {
                                    children: resetAlert,
                                    color: "error",
                                    display: "initial"
                                  }) : null, React.createElement(Core.Button, {
                                  style: {
                                    margin: "16px"
                                  },
                                  children: "Reset Password",
                                  color: "primary",
                                  disabled: match$3[0],
                                  type: "submit",
                                  variant: "contained"
                                })),
                        alignItems: "center",
                        display: "flex",
                        flexDirection: "column",
                        gridGap: 24
                      }) : React.createElement(Core.Typography, {
                        align: "center",
                        children: "The URL is incorrect. Request another reset link."
                      })));
}

ResetPassword.displayName = "ResetPassword";

var supabase = SupabaseClient$Weblab.supabase;

var make = ResetPassword;

var $$default = ResetPassword;

export {
  theme ,
  supabase ,
  loginReducer ,
  make ,
  $$default ,
  $$default as default,
  
}
/* theme Not a pure module */
